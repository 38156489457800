.p-setting-active {
    
    background-color: white;
    color: black;
    height: 60px;
    font-size: 15px;
    font-weight: bold;
    padding: 0px 40px;
    margin-right: 20px;
    background-color: rgb(2, 2, 104);
    border:none;
    color: aliceblue;
    border-radius: 5px;

  }
  
  .p-setting-inactive {
    height: 60px;
    margin-right: 20px;
    color: black;
    font-weight: bold;
    border: none;
    cursor: pointer;
    padding: 0px 40px;
    font-size: 15px;
    font-weight: bold;
  }